import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import DelwarHossain from "../assets/imgs/ZamZam_Team_Imam_Delwar_Hossain.png";
import HafizRayhanUddin from "../assets/imgs/ZamZam_Team_Hafiz_Rayhan_Uddin_1.png";
import AlMakki from "../assets/imgs/ZamZam_Team_Shaikh_Ismail_Al-Makki.jpg";
import migImgOne from "../assets/imgs/ZamZam_Hajj_Kafela_2019_ToJamarat_2_.png";
import migImgTwo from "../assets/imgs/ZamZam_Hajj_Kafela_2016_Jamarat_1.png";
import Kafela_2016 from "../assets/imgs/ZamZam_Hajj_Kafela_2016_Kaaba.png";
import ToJamarat from "../assets/imgs/Gallery/ZamZam_Hajj_Kafela_2019_ToJamarat.png";

import "../assets/css/style.css";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
});

function AboutUs() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="AboutUsContainer SectionContainer">
      <Grid container spacing={3}>
        <Grid item md={4} xs={12}>
          <div className="AboutImgContainer">
            <Grid container spacing={1}>
              <Grid item md={12} xs={12}>
                <div className="aboutImg hieght_320">
                  {" "}
                  <img
                    src={ToJamarat}
                    alt="ZamZam_Hajj_Kafela_2019_ToJamarat-Makki"
                  />
                </div>
              </Grid>

              <Grid item md={6} xs={12}>
                <div className="aboutImg hieght_172">
                  {" "}
                  <img src={migImgOne} alt="Logo" />
                </div>
              </Grid>

              <Grid item md={6} xs={12}>
                <div className="aboutImg hieght_172">
                  {" "}
                  <img src={migImgTwo} alt="Logo" />
                </div>
              </Grid>

              <Grid item md={12} xs={12}>
                <div className="aboutImg hieght_198">
                  {" "}
                  <img src={Kafela_2016} alt="Logo" />
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>

        <Grid md={8} xs={12}>
          <div className="AboutUstCol">
            <div className="GroupButtonContainer">
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                centered
              >
                <Tab label="English" />
                <Tab label="বাংলা" />
                <Tab label="عربى" />
              </Tabs>
            </div>

            <TabPanel value={value} index={0}>
              <h1 className="sectionTitle textDark">About Us</h1>
              <p>
                Alhamdulillah, we have been serving the guests of Allah in
                performing Hajj-Umrah for ten long years.
              </p>
              <p>
                Our communication on our services is very transparent and
                committed to provide the following - 1) Service as promised. 2)
                Lead by experienced Imam-Alem. 3) Assurance of performing Hajj
                and Umrah correctly. 4) Organizing luxury hotels nearby and
                close to the Masjide Haram at Makkah and the Masjide Nawabi
                (Prophet's Mosque) to stay and good meals served. 5) The
                directors of the Group are under constant supervision for Hajij.
                6) Arranging sessions and teaching Islam throughout the Hajj
                period. 6) Suitable package for American Muslims.
              </p>
              <p>
                We thank Allah Subhanahu o tala for the countless positive
                testimonials we received from our guests. Contact us at{" "}
                <a className="CellNumber" href="tel: +1 (917) 540-7944">
                  +1 (917) 540-7944
                </a>{" "}
                to perform yearly Hajj and Umrah alone or with your family from
                anywhere in the United States.
              </p>
              <div className="Boutfollowsection">
                <p>
                  Email:{" "}
                  <a href="mailto:yeszamzam@gmail.com" target="_blank">
                    yeszamzam@gmail.com
                  </a>
                </p>
                <p>
                  Website: <a>www.zamzamhajj.com</a>
                </p>
                <p>
                  Follow us on Facebook:{" "}
                  <a
                    href="https://www.facebook.com/ZamzamHajjUSA/"
                    target="_blank"
                  >
                    {" "}
                    https://www.facebook.com/ZamzamHajjUSA/
                  </a>
                </p>
              </div>

              {/* <Link>Read more...</Link> */}
            </TabPanel>

            <TabPanel value={value} index={1}>
              <h1 className="sectionTitle textDark">About Us</h1>
              <p>
                আলহামদুলিল্লাহ, আমরা দীর্ঘ দশ বছর যাবত হজ্জ- উমরাহ পালনে আল্লাহ
                মেহমানদের খেদমত করে যাচ্ছি ।{" "}
              </p>
              <p>
                আমাদের বৈশিষ্ট্য হলঃ ১) প্রতিশ্রুতি অনুযায়ী সেবা । ২) অভিজ্ঞ
                ইমাম-আলেম কর্তৃক পরিচালিত । ৩) ছহিহ শুদ্ধভাবে হজ্জ ও উমরাহ্
                পালনের নিশ্চয়তা । ৪) মসজিদে হারাম ও মসজিদে নববী সংলগ্ন
                উন্নতমানের হোটেলে থাকা খাওয়ার সু-ব্যবস্থা । ৫) কাফেলার
                পরিচালকবৃন্দ হাজীসাহেবদের সাথে থেকেই সার্বক্ষণিক তত্ত্বাবধান ।
                ৬) হজ্জের পুরো সময়টা জুড়ে তালিম তরবিয়াতের ব্যবস্থা । ৭) আমেরিকান
                মুসলিমদের উপযোগী প্যাকেজ ।{" "}
              </p>
              <p>
                আমাদের অতিথিদের কাছ থেকে পাওয়া অসংখ্য ইতিবাচক প্রশংসাপত্রের জন্য
                আমরা আল্লাহ্র শুকরিয়া আদায় করছি । আমেরিকার যে কোন প্রান্ত থেকে
                জমজমের ব্যবস্থাপনায় একা অথবা পরিবারসহ হজ্জ এবং উমরাহ পালনে
                আমাদের সাথে যোগাযোগ করুনঃ (৯১৭) ৩৪৮-৬৪১৬, (৭১৮) ৩৪৮-১৮৫৩
              </p>
              {/* <p>Email: yeszamzam@gmail.com আরো বিস্তারিত তথ্যের জন্য </p>
                            <p>আমাদের ওয়েবসাইট ভিজিট করুনঃ www.zamzamhajj.com </p> */}

              <div className="Boutfollowsection">
                <p>
                  Email:{" "}
                  <a href="mailto:yeszamzam@gmail.com" target="_blank">
                    yeszamzam@gmail.com আরো বিস্তারিত তথ্যের জন্য
                  </a>
                </p>
                <p>
                  আমাদের ওয়েবসাইট ভিজিট করুনঃ <a>www.zamzamhajj.com</a>
                </p>
                <p>
                  ফেসবুকে আমাদের অনুসরণ করুন:{" "}
                  <a
                    href="https://www.facebook.com/ZamzamHajjUSA/"
                    target="_blank"
                  >
                    {" "}
                    https://www.facebook.com/ZamzamHajjUSA/
                  </a>
                </p>
              </div>
            </TabPanel>

            <TabPanel value={value} index={2}>
              <h1 className="sectionTitle textDark">About Us</h1>
              <p>
                الحمد لله، نحن نخدم ضيوف الرحمن في أداء مناسك الحج والعمرة منذ
                عشر سنوات طويلة.
              </p>
              <p>
                ميزاتنا هي: 1، الخدمة الموعودة. 2، الإرشاد بالأئمة والعلماء ذوي
                الخبرة. 3، التأكد من أداء فريضة الحج والعمرة بالطريقة الصحيحة.
                4، تنظيم الفنادق الفاخرة المجاورة والقريبة من المسجد الحرام
                والمسجد النبوي الشريف للبقاء ولتناول الوجبات. 5، مديري ومرشدي
                القافلة تحت إشراف دائم مع الحجاج. 6، ترتيب التدريب والتعليم طوال
                فترة الحج بأكملها. 7، حزمة مناسبة للمسلمين الأمريكيين.
              </p>
              <p>
                نشكر الله على الشهادات الإيجابية الكثيرة التي تلقيناها من ضيوفنا
                الكرام. تواصل معنا لأداء فريضة الحج والعمرة بمفردك أو مع أسرتك
                مع إدارة زمزم من أي مكان أو ولاية من أمريكا:{" "}
              </p>
              <p>(347) 422-7712. </p>

              <div className="Boutfollowsection">
                <p>
                  Email:{" "}
                  <a href="mailto:yeszamzam@gmail.com" target="_blank">
                    yeszamzam@gmail.com
                  </a>
                </p>
                <p>
                  Website: <a href="www.zamzamhajj.com">www.zamzamhajj.com</a>
                </p>
                <p>
                  تابعنا على الفيسبوك:{" "}
                  <a
                    href="https://www.facebook.com/ZamzamHajjUSA/"
                    target="_blank"
                  >
                    {" "}
                    https://www.facebook.com/ZamzamHajjUSA/
                  </a>
                </p>
              </div>
            </TabPanel>
          </div>

          <div className="ZamzamTeamCol">
            <h1 className="sectionTitle textDark">ZAMZAM team</h1>
            <Grid container spacing={3}>
              <Grid item md={4} xs={12}>
                <div className="ZamzamTeamCard">
                  <div className="ZamzamTeamCardImg">
                    {/* <img src={DelwarHossain} alt="Logo" /> */}
                    <img
                      src="https://zamzamhajj-media.s3.amazonaws.com/Ababil-Imam-Dalouer-Zamzam.png"
                      alt="Logo"
                    />
                  </div>
                  <h3>Imam Dalouer Hossain</h3>
                  <p>President, ZamZam Hajj & Umrah Services</p>
                  <p>Imam & Khateeb, Baitul Mamur Masjid, NY</p>
                </div>
              </Grid>

              <Grid item md={4} xs={12}>
                <div className="ZamzamTeamCard">
                  <div className="ZamzamTeamCardImg">
                    <img src={HafizRayhanUddin} alt="Logo" />
                  </div>
                  <h3>Hafiz Rayhan Uddin</h3>
                  <p>Imam</p>
                  <p>Masid Al Falah, MI</p>
                </div>
              </Grid>

              <Grid item md={4} xs={12}>
                <div className="ZamzamTeamCard">
                  <div className="ZamzamTeamCardImg">
                    <img src={AlMakki} alt="Logo" />
                  </div>
                  <h3>Shaikh Dr. Md Ismail Hossain Makki</h3>
                  <p>Country Director, UAE</p>
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

function TabPanel(props) {
  const { children, value, index } = props;

  return <div>{value === index && <h1>{children}</h1>}</div>;
}

export default AboutUs;
