import React from "react";
import { PageTitleOne } from "master-react-lib";
import HeaderSection from "../Common/HeaderSection";
import FooterSection from "../Common/FooterSection";
import Grid from "@material-ui/core/Grid";

import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import backgroundPattern from "../../assets/imgs/PagePattern.svg";

import CardOne from "../../assets/imgs/umrah/Zamzam-Omrah-2023-2024.png";
import CardTwo from "../../assets/imgs/umrah/ZamZam-Umrah-2024-February-Ramadhan2024.png.png";
import CardThree from "../../assets/imgs/umrah/Platinum-Umrah-Package-November2022.png";

import MidWinter from "./UmrahDetails/MidWinter";
import RamadhanSpringBreak from "./UmrahDetails/RamadhanSpringBreak";
import PackageAug from "../../assets/imgs/zamzam-August-2024.png";
import PackageJuly from "../../assets/imgs/zamzam-July-2024.png";
import UmrahSep from "./UmrahDetails/UmrahSep";
import UmrahNov from "./UmrahDetails/UmrahNov";
import UmrahNovEgypt from "./UmrahDetails/UmrahNovEgypt";
import UmrahOct from "./UmrahDetails/UmrahOct";
import UmrahDec from "./UmrahDetails/UmrahDec";

function UmrahPage(props) {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const packgesList = [
    {
      img: PackageJuly,
      title: "JULY PACKAGE",
      date: "Jul 21, 2024- Jul 29, 2024",
      day: "Makkah 4 days , Medina 3 days",
      details: <MidWinter />,
    },

    {
      img: PackageAug,
      title: "AUGUST PACKAGE",
      date: "Aug 18, 2024 - Aug 26, 2024",
      day: "Makkah 4 nights , Medina 3 nights",
      details: <RamadhanSpringBreak />,
    },
    {
      img: "https://zamzamhajj-media.s3.amazonaws.com/zamzam-sep-2024.png",
      title: "SEPTEMBER PACKAGE",
      date: "Sep 18, 2024 - Sep 26, 2024",
      day: "Makkah 4 nights , Medina 3 nights",
      details: <UmrahSep />,
    },
    {
      img: "https://zamzamhajj-media.s3.amazonaws.com/zamzam-oct-2024.png",
      title: "OCTOBER PACKAGE",
      date: "Oct 19, 2024 - Oct 27, 2024",
      day: "Makkah 4 nights , Medina 3 nights",
      details: <UmrahOct />,
    },
    {
      img: "https://zamzamhajj-media.s3.amazonaws.com/zamzam-nov-2024.png",
      title: "NOVEMBER PACKAGE",
      date: "Nov 22, 2024 - Nov 30, 2024",
      day: "Makkah 4 nights , Medina 3 nights",
      details: <UmrahNov />,
    },
    {
      img: "https://zamzamhajj-media.s3.amazonaws.com/zamzam-Nov-Egypt+2024.png",
      title: "NOVEMBER EGYPT PACKAGE",
      date: "Nov 22, 2024 - Dec 04, 2024",
      day: "Makkah 4 nights , Medina 3 nights",
      details: <UmrahNovEgypt />,
    },
    {
      img: "https://zamzamhajj-media.s3.amazonaws.com/zamzam-december-2024.png",
      title: "DECEMBER PACKAGE",
      date: "Dec 24, 2024 - Jan 01, 2024",
      day: "Makkah 4 nights , Medina 3 nights",
      details: <UmrahDec />,
    },
  ];

  return (
    <div className="MainContainer">
      <HeaderSection />

      <div className="innterContainer">
        <PageTitleOne
          Title="Umrah 2024 Packages"
          SubTitle="ZamZam Hajj & Umrah"
          color="#fff"
          backgroundcolor="#56B149"
          backgroundPattern={backgroundPattern}
        />

        <div className="innerPageBody">
          <Grid container spacing={3}>
            {packgesList.map((item, i) => (
              <Grid item md={12} sm={6} xs={12}>
                <div className="Umracard">
                  <Accordion
                    expanded={expanded === item.img}
                    onChange={handleChange(item.img)}
                  >
                    <div className="UmracardContianer">
                      <div className="UmracardImg">
                        <img src={item.img} />
                      </div>
                      <div className="UmracardDetails">
                        <h1>{item.title}</h1>
                        <h2>{item.date}</h2>
                        <h3>{item.day}</h3>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id={item.img}
                        >
                          <Typography>SEE MORE DETAILS</Typography>
                        </AccordionSummary>
                      </div>
                    </div>

                    <AccordionDetails>
                      <div className="DetailsSection">{item.details}</div>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </Grid>
            ))}
          </Grid>
        </div>
      </div>

      <FooterSection />
    </div>
  );
}

export default UmrahPage;
