import React, { Component } from "react";
import { MainBanner1, LinkCard } from "master-react-lib";
import Grid from "@material-ui/core/Grid";
import ZamZam_Hajj_Umrah_2021 from "../assets/imgs/ZamZam_Hajj_Umrah_2021.png";
import DelwarHossain from "../assets/imgs/ZamZam_Team_Imam_Delwar_Hossain.png";
import LogoIcon from "../assets/imgs/ZamZam_Hajj_Umrah_LogoIcon.png";

import bannerOne from "../assets/imgs/ZamZam_Hajj_Umrah_Homepage_Banner_1.png";
// import bannerTwo from '../assets/imgs/ZamZam-HajjKafela-Homepage-Banner-Hajj-2023.png';
import bannerTwo from "../assets/imgs/best-umrah-2023-2024-zamzam-hajj-website.webp";

import MianUdin from "../assets/imgs/ZamZam_Hajj_Umrah_Main_Uddin_Shipon.jpeg";
import Abu_Bakar_Shiddque_Hashina_Akter from "../assets/imgs/ZamZamHajj_Umrah_Abu_Bakar_Shiddque.jpeg";
import Imam_Abdullatif_Azom from "../assets/imgs/ZamZam_Hajj_Umrah_Imam_Abdullatif_Azom.png";
import Osman_Gani from "../assets/imgs/ZamZam_Hajj_Umrah_Dr_Ataul_Osmani.jpeg";
import Kazi_Ismail from "../assets/imgs/ZamZam_Hajj_Umrah_Kazi_Ismail.png";

// import Alert from '@material-ui/lab/Alert';
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";

function Banner(props) {
  const cardData = [
    {
      title: "About ZamZam Hajj Kafela",
      singleImg: DelwarHossain,
    },
  ];

  const cardData_2 = [
    {
      title: "ZamZam 2024 Umrah Packages",
      singleImg:
        "https://zamzamhajj-media.s3.amazonaws.com/zamzam-umrah-package-2024.jpg",
    },
  ];

  const cardData_3 = [
    {
      title: "Hear about us from our Hajis",
      GroupImg: Imam_Abdullatif_Azom,
      GroupImg2: Osman_Gani,
      GroupImg3: Kazi_Ismail,
      GroupImg4: MianUdin,
      GroupImg5: Abu_Bakar_Shiddque_Hashina_Akter,
    },
  ];

  const bannerData = [
    {
      // label: 'Welcome to ZamZam',
      // Descrition: 'The Muslim greeting of salam “saying as-salamu alaykum” in Arabic means “peace be upon you”',
      imgPath: bannerOne,
      Link: "https://zamzamhajj-media.s3.amazonaws.com/zamzam-umrah-package-2024.jpg",
    },

    // {
    //     imgPath: bannerTwo,
    //     Link: "https://dev.zamzamhajj.com/imglib/ZamZam_Umrah_2021.png"
    // },

    // {
    //     imgPath: bannerTwo,
    //     Link: "https://www.facebook.com/ZamzamHajjUSA/"
    // },
  ];

  const [open, setOpen] = React.useState(true);

  return (
    <div className="SectionContainer mobileView">
      <Grid container spacing={1}>
        <Grid item md={9} sm={7} xs={12}>
          <MainBanner1 tutorialSteps={bannerData} BannerButton="More Details" />
        </Grid>

        <Grid item md={3} sm={5} xs={12}>
          <Grid container spacing={1}>
            <Grid item md={12} sm={12} xs={12}>
              <LinkCard
                cardData={cardData}
                singleImg={true}
                Arrow={false}
                AvatarGroup={false}
                path=""
                link="/#aboutUs"
              />
            </Grid>

            <Grid item md={12} sm={12} xs={12}>
              <LinkCard
                cardData={cardData_2}
                singleImg={false}
                Arrow={true}
                AvatarGroup={false}
                path=""
                // link="https://d2doewlpstuw7k.cloudfront.net/ZamZam_Registration Form_2023.pdf"
                link="https://zamzamhajj-media.s3.amazonaws.com/zamzam-umrah-package-2024.jpg"
              />
            </Grid>

            <Grid item md={12} sm={12} xs={12}>
              <LinkCard
                cardData={cardData_3}
                singleImg={false}
                Arrow={true}
                AvatarGroup={true}
                //path=""
                link="/#aboutUs"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* <div className='AlertBanner'>
                <Collapse in={open}>
                    <Alert
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setOpen(false);
                                }}
                            >
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                        severity="info"
                    >
                        <div className='AlertCont'>Registration for Hajj 2023 already started. Please register early to confirm your booking.<br></br>Click here for download: <a href="https://d2doewlpstuw7k.cloudfront.net/ZamZam_Registration Form_2023.pdf" target='_blank'>Register now for Hajj 2023</a></div>
                    </Alert>
                </Collapse>
            </div> */}
    </div>
  );
}

export default Banner;
